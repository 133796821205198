import React, {useEffect, useState} from "react"
import {getCheapestFlightsFxn} from "../../containers/flightSearch/actions";
import {displayDate, displayDateOnly, formatRupees} from "../utils/appUtils";
import {getAirlineLogo} from "../utils/appUtils";
import Background from "../../assets/searchImg/search-pattern.png";
import {useNavigate} from "react-router-dom";
import {useDispatch} from "react-redux";

let rupessSymbol = "₹"
let $ = window.$;
let imgUrl = "https://images.pexels.com/photos/3254729/pexels-photo-3254729.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
const SingleCardBlockOld = () => {
    return (
        <div>
            <div className="content">
                {/*  <div className="image-fade-wrapper">
                    <img src="https://images.pexels.com/photos/3254729/pexels-photo-3254729.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1" alt="Fading Image"/>
                    <a href="#" className="btn">
                        View Detail
                    </a>
                </div>*/}

                <span className="box">
                                        <img
                                            src="https://images.pexels.com/photos/3254729/pexels-photo-3254729.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
                                            alt="dummy-image"
                                        />
                                        <a href="#" className="btn">
                                          View Detail
                                        </a>
                                      </span>
                <h6>
                    Copenhagen <span>Rs. 25266</span>
                </h6>
            </div>
        </div>
    )
}
const SingleCardBlock = ({item, events}) => {
    let {cheapest, preferredDepartureTime} = item
    // let marketPrice = (cheapest['Fare'] || 0) + 1000
    let yourPrice = ((cheapest['BaseFare'] || 0) + (cheapest['Tax'] || 0) + (cheapest['OtherCharges'] || 0))
    let marketPrice = yourPrice + 1000
    if (yourPrice > 0) {
        // yourPrice = yourPrice - 1000
    }

    return (
        <div className={'card-box'} style={{backgroundImage: `url(${Background})`}}>
            <div className={'flight-card'} key={item.currency}>
                <div className={'d-flex justify-content-between'}>
                    <div>
                        <div className={'flight-info mb-0'}>
                            {item.fromArea} - {item.toArea}
                        </div>
                        <div className={'mb-0'}>
                            ({item.origin} - {item.destination})
                        </div>
                    </div>
                    <img src={getAirlineLogo(cheapest.AirlineCode)} style={{height: 35, width: 'auto'}}/>
                </div>
                <div className={'mt20'}>
                    <div className={'d-flex market-price-card'}>
                        Market Price : <div
                        className={'market-price'}>&nbsp;{rupessSymbol} {formatRupees(Math.round(marketPrice))}</div>
                    </div>
                    <div className={'d-flex justify-content-between align-content-center mt-2'}>
                        <div className={'flight-rate'}>
                            Your Price : <div className={'rate'}>
                            {rupessSymbol}&nbsp;{formatRupees(Math.round(yourPrice))}
                        </div>
                        </div>
                        <a className={'btn btn-primary rounded-pill px-3'} onClick={() => {
                            events.goToBookNow(item)
                        }}>Book Now</a>
                    </div>
                </div>

            </div>
        </div>
    )
}
const ExclusiveDeals = () => {
    let [flightData, setFlightData] = useState({})
    const navigate = useNavigate();
    let dispatch = useDispatch()

    const initializeSlick = () => {
        try {
            $('.offers-box').not('.slick-initialized').slick({
                dots: false,
                speed: 300,
                slidesToShow: 4,
                slidesToScroll: 1,
                infinite: true,
                arrows: true,
                responsive: [
                    {
                        breakpoint: 1400,
                        settings: {
                            slidesToShow: 4,
                            slidesToScroll: 1
                        }
                    },
                    {
                        breakpoint: 600,
                        settings: {
                            slidesToShow: 3,
                            slidesToScroll: 1
                        }
                    },
                    {
                        breakpoint: 480,
                        settings: {
                            slidesToShow: 1,
                            slidesToScroll: 1
                        }
                    }
                ]
            });
        } catch (e) {

        }
    };
    useEffect(() => {

        return () => {
            if ($('.offers-box').hasClass('slick-initialized')) {
                $('.offers-box').slick('unslick'); // Destroy Slick instance
            }
        };
    }, []);
    const events = {
        loadCheapestFlightsFxn: async () => {
            let {data, success} = await getCheapestFlightsFxn()
            if (success && data && data.response) {
                setFlightData(data.response)

                setTimeout(() => {
                    initializeSlick();
                }, 100)
            }
        },
        goToBookNow: (item) => {
            let {origin, destination, preferredDepartureTime, fromArea, toArea, cheapest} = item;
            let {DepartureDate} = cheapest;

            let obj = {
                cabinClass: '2',
                adultCount: 1,
                childCount: 0,
                infantCount: 0,
                resultFareType: '2',
                preferredDepartureTime: DepartureDate,
                origin: origin,
                originCity: fromArea,
                destination: destination,
                destinationCity: toArea,
                preferredReturnTime: null,
                journeyType: '1'
            }
            dispatch({type: "SEARCH_FLIGHT", flightObj: obj})

            setTimeout(() => {
                navigate('/flight-search-result')
            }, 200)
        }
    }
    useEffect(() => {
        events.loadCheapestFlightsFxn()
    }, [])

    return (
        <>
            <section className="deal-offer spacing-60 pb-0">
                <div className="container">
                    <div className="head-1 text-center mb-lg-5 mb-4">
                        <div className="row">
                            <div className="col-lg-7 mx-auto">
                                <h3 className="mb-3">
                                    <img src="images2/planeNew.png" alt="plane" width={28}/>{" "}
                                    Exclusive Deals &amp; Offers{" "}
                                    <img src="images2/planeNew.png" alt="plane" width={28}/>
                                </h3>
                                <p className="m-0">
                                    Unlock unbeatable rates with X Travel World! Our platform offers exclusive deals on
                                    flights and hotels, available only to our B2B partners. With direct access to
                                    discounted prices and special promotions, you can provide your clients with
                                    exceptional value while maximizing your business profits.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="slides">
                        <div className="offers-box flight-list">
                            {flightData && flightData.length ? flightData.map((item) => {
                                return (
                                    <SingleCardBlock item={item} events={events}/>
                                )
                            }) : null}
                        </div>
                    </div>
                    {/*  <div className="text-center mt-3">
                        <a href="#" className="btn btn-primary rounded-pill px-4">
                            View All
                        </a>
                    </div>*/}
                </div>
            </section>
        </>
    )
}
export default ExclusiveDeals
