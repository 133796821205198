export const searchFlightUrl = () => {
    return "/x-travel/search"
}

export const airLinesUrl = () => {
    return "/x-travel/air-lines"
}

export const flightsCodeUrl = () => {
    return "/x-travel/flights-code"
}

export const flightsFearRuleUrl = () => {
    return "/x-travel/fare-rules"
}
export const flightsFearQuoteUrl = () => {
    return "/x-travel/fare-quote"
}

export const lccFlightTicketUrl = () => {
    return "/x-travel/ticket-flight-lcc"
}

export const noLccFlightTicketUrl = () => {
    return "/x-travel/ticket-flight-no-lcc"
}

export const singleTicketUrl = () => {
    return "/x-travel/single-ticket"
}

export const flightsSearchUrl = () => {
    return "/x-travel/search-flights"
}

export const getAllTransfer = () => {
    return "/x-travel/getAllTransfer"
}

export const holdFlightBookUrl = () => {
    return "/x-travel/hold-flight-booking"
}
export const ticketBySessionIdUrl = () => {
    return "/x-travel/single-ticket-by-sessionId"
}
export const getWalletBalanceUrl = () => {
    return "/x-travel/get-agent-bal"
}

export const releasePnrUrl = () => {
    return "/x-travel/release-pnr"
}

export const getUpdatedSingleTicketUrl = () => {
    return "/x-travel/get-updated-single-ticket"
}

export const recentSearchFlightsUrl = () => {
    return "/x-travel/recent-search"
}

export const getLastTenPassengersUrl = () => {
    return "/x-travel/getLastTenPassengers"
}

export const shareItineraryUrl = () => {
    return "/x-travel/share-itinerary"
}

export const getCheapestFlightsUrl = () => {
    return '/api/get-cheapest-flights'
}
