import {
    searchFlightUrl,
    flightsCodeUrl,
    airLinesUrl,
    flightsFearRuleUrl,
    flightsFearQuoteUrl,
    lccFlightTicketUrl,
    noLccFlightTicketUrl,
    singleTicketUrl,
    flightsSearchUrl,
    getAllTransfer,
    holdFlightBookUrl,
    ticketBySessionIdUrl,
    getWalletBalanceUrl,
    releasePnrUrl,
    getUpdatedSingleTicketUrl,
    getLastTenPassengersUrl,
    recentSearchFlightsUrl, shareItineraryUrl, getCheapestFlightsUrl
} from "../apis";
import {customAxios as axios, getHeader} from "../../../request";
import {notification} from "../../../components/Elements";
import {showLoader, hideLoader} from "../../../actions/loader";


export const airLinesFxn = async (valData) => {
    let config = {
        params: {...valData},
        ...await getHeader()
    }
    let {data} = await axios.get(airLinesUrl(), config)
    return data.data;
}
export const flightsCodeFxn = async (valData) => {
    let config = {
        params: {...valData}
    }
    let {data} = await axios.get(flightsCodeUrl(), config)
    return data.data;
}

export const searchFlightFxn = (valData) => async (dispatch) => {
    // dispatch(showLoader())
    let {data} = await axios.post(searchFlightUrl(), valData)
    dispatch(hideLoader())
    if (data.error) {
        notification.error({
            message: data.message || 'Error'
        })
    }
    return data;
}
export const flightsFearRuleFxn = (valData) => async (dispatch) => {
    dispatch(showLoader())
    let {data} = await axios.post(flightsFearRuleUrl(), valData)
    dispatch(hideLoader())
    /*  if (!data.error) {
          notification.success({
              message: data.message || 'Success'
          })
      } else {
          notification.error({
              message: data.message || 'Error'
          })
      }*/
    return data;
}


export const getFlightFearRuleFxn = async (valData) => {
    let {data} = await axios.post(flightsFearRuleUrl(), valData)
    return data;
}

export const flightsFearQuoteFxn = (valData) => async (dispatch) => {
    dispatch(showLoader())
    let {data} = await axios.post(flightsFearQuoteUrl(), valData)
    dispatch(hideLoader())

    if (!data.error) {
        // notification.success({
        //     message: data.message || 'Success'
        // })
    } else {
        // notification.error({
        //     message: data.message || 'Error'
        // })
    }
    return data;
}

export const lccFlightTicketFxn = (valData) => async (dispatch) => {
    dispatch(showLoader())
    let {data} = await axios.post(lccFlightTicketUrl(), valData)
    dispatch(hideLoader())
    // if (!data.error) {
    //      notification.success({
    //          message: data.message || 'Success'
    //      })
    //  } else {
    //      notification.error({
    //          message: data.message || 'Error'
    //      })
    //  }
    return data;
}

export const noLccFlightTicketFxn = (valData) => async (dispatch) => {
    dispatch(showLoader())
    let {data} = await axios.post(noLccFlightTicketUrl(), valData)
    dispatch(hideLoader())
    if (!data.error) {
        notification.success({
            message: data.message || 'Success'
        })
    } else {
        notification.error({
            message: data.message || 'Error'
        })
    }
    return data;
}

export const singleTicketFxn = (valData) => async (dispatch) => {
    dispatch(showLoader())
    let {data} = await axios.post(singleTicketUrl(), valData)
    dispatch(hideLoader())
    /* if (!data.error) {
         notification.success({
             message: data.message || 'Success'
         })
     } else {
         notification.error({
             message: data.message || 'Error'
         })
     }*/
    return data;
}
export const flightsSearchFxn = async (valData) => {
    let config = {
        params: {...valData},
        ...await getHeader()
    }
    let {data} = await axios.get(flightsSearchUrl(), config)
    return data;
}

export const listAllTransferFunctionFxn2 = (valDate) => async (dispatch) => {
    dispatch(showLoader())
    let config = {
        headers: {Authorization: 'Bearer ' + localStorage.getItem('token')},
        params: {...valDate}
    }
    let {data} = await axios.get(getAllTransfer(), config)
    dispatch(hideLoader())
    if (data.error) {
        notification.error({
            message: data.message || 'Error'
        })
    }
    return data.data
}

export const holdFlightBookFxn = (valData) => async (dispatch) => {
    dispatch(showLoader())
    let {data} = await axios.post(holdFlightBookUrl(), valData)
    dispatch(hideLoader())
    if (!data.error) {
        notification.success({
            message: data.message || 'Success'
        })
    } else {
        notification.error({
            message: data.message || 'Error'
        })
    }
    return data;
}

export const ticketBySessionIdFxn = (valData) => async (dispatch) => {
    // dispatch(showLoader())
    let {data} = await axios.post(ticketBySessionIdUrl(), valData)
    // dispatch(hideLoader())
    return data;
}

export const getWalletBalanceFxn = () => async (dispatch) => {
    // dispatch(showLoader())
    let {data} = await axios.post(getWalletBalanceUrl(), {})
    // dispatch(hideLoader())
    return data;
}


export const releasePnrFxn = () => async (dispatch) => {
    // dispatch(showLoader())
    let {data} = await axios.post(releasePnrUrl(), {})
    dispatch(hideLoader())
    return data;
}

export const getUpdatedSingleTicketFxn = (valData) => async (dispatch) => {
    dispatch(showLoader())
    let {data} = await axios.post(getUpdatedSingleTicketUrl(), valData)
    dispatch(hideLoader())
    return data;
}

export const recentSearchFlightsFxn = async (valData) => {
    let config = {
        params: {...valData},
        ...await getHeader()
    }
    let {data} = await axios.get(recentSearchFlightsUrl(), config)
    return data.data;
}
export const getLastTenPassengersDetails = async (valData) => {
    let {data} = await axios.post(getLastTenPassengersUrl(), valData)
    return data;
}

export const shareItineraryFxn = (valData) => async (dispatch) => {
    dispatch(showLoader())
    let {data} = await axios.post(shareItineraryUrl(), valData)
    dispatch(hideLoader())
    if (!data.error) {
        notification.success({
            message: data.message || 'Success'
        })
    } else {
        notification.error({
            message: data.message || 'Error'
        })
    }
    return data;
}
export const getCheapestFlightsFxn = async (valData) => {
    let {data} = await axios.post(getCheapestFlightsUrl(), valData)
    return data
}
